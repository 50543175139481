<template>
  <div class="dialog-error">
    <el-dialog 
      width="80%" 
      top="10vh" 
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <el-row>
        <el-col>
          <template>
            <el-result icon="error" />
          </template>
          <template>
            <div class="error-container">
              <div class="error-title">
                {{ $t(`errorMessage.${errorCode}`) }}
              </div>
              <template v-if="isUndefinedField">
                <div v-for="(item, index) in errorFieldList" :key="index">
                  -  {{ item }}
                </div>
              </template>
              <template v-else-if="isDatetimeField">
                <div />
              </template>
              <template v-else>
                <div v-for="(item, index) in errorFieldList" :key="index">
                  -  {{ $t(`field.${item}`) }}
                </div>
              </template>
            </div>
          </template>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ErrorDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    errorFieldList: {
      type: Array,
      default: () => []
    },
    errorCode: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
    }
  },
  computed: {
    isUndefinedField() {
      let result = false
      if (this.errorCode === 4002) {
        result = true
      }
      return result
    },
    isDatetimeField() {
      let result = false
      if (this.errorCode === 4004) {
        result = true
      }
      return result
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    handleCollapseChange(val) {
      // console.log(val)
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/index.scss";
.dialog-error {
  /* reset element-ui css */
  .el-dialog__body {
    padding: 0 20px 40px;
  }

  .el-result {
    display: block;
    padding: 0;
  }
  /* reset element-ui css */

  .el-dialog__header {
    position: relative;
  }

  .el-dialog__header .btn-copy{
    border: none;
    position: absolute;
    padding: 0;
    font-size: 16px;
    right: 50px;
  }

  .error-container{
    padding: 20px 75px 50px;
    font-size: 16px;
    line-height: 2;

    .error-title{
      font-size: 20px;
      font-weight: 700;
    }
  }
}

  .el-collapse {
    border-top: none;
    border-bottom: none;
  }
  
  .collapse-container{
    margin: 30px 10px;
  }

  .el-collapse-item__header {
    font-size: 20px;
  }
</style>
