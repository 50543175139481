<template>
  <div class="dialog-success">
    <el-dialog 
      width="80%" 
      top="10vh" 
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <el-row>
        <el-col>
          <!-- icon block -->
          <template>
            <template v-if="returnStatus =='error'">
              <el-result icon="error" />
            </template>
            <template v-else-if="returnStatus =='success'">
              <el-result icon="success" />
            </template>
            <template v-else-if="returnStatus =='warning'">
              <el-result t icon="warning" />
            </template>
          </template>
          <!-- card block -->
          <template>
            <el-row
              :gutter="12" class="summary-row"
              style="width: 90%warningel-resul; margin: 10px auto;"
            >
              <el-col :span="8">
                <el-card
                  class="summary-card"
                >
                  <p>{{ $t('excelUpload.success_upload') }} / {{ $t('excelUpload.total_upload') + $t('excelUpload.count') }}</p>
                  <p class="card-number number-success">
                    {{ countSuccess }} / 
                    <span class="card-number number-total">{{ countTotal }}</span>
                  </p>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card
                  class="summary-card number-missing"
                >
                  <p>{{ $t('excelUpload.missing_upload') + $t('excelUpload.count') }}</p>
                  <p class="card-number">
                    {{ countMissing }}
                  </p>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card
                  class="summary-card number-duplicate"
                >
                  <p> {{ $t('excelUpload.duplicate_upload') + $t('excelUpload.count') }}</p>
                  <p class="card-number">
                    {{ countDuplicate }}
                  </p>
                </el-card>
              </el-col>
            </el-row>
          </template>
        </el-col>
      </el-row>
      <template v-if="isDataCorrect">
        <div class="data-correct-container">
          {{ $t('excelUpload.correct_upload') }} 
          <span>
            <router-link :to="{name:'ExcelUpload'}"> {{ $t('excelUpload.toUploadPage') }} </router-link>
          </span>
        </div>
      </template>
      <!-- table block -->
      <template>
        <div class="collapse-container">
          <el-collapse v-model="activeNames" @change="handleCollapseChange">
            <template v-if="isMissing">
              <el-collapse-item :title="$t(`excelUpload.missing_required_fields_nums`)" name="missingFields">
                <el-table :data="missingData" max-height="600" stripe>
                  <el-table-column property="row" :label="$t(`excelUpload.index`)" width="100" />
                  <el-table-column property="userId" :label="$t(`excelUpload.userId`)" width="150" />
                  <el-table-column property="fields" :label="$t(`excelUpload.missing_fields`)">
                    <template slot-scope="scope">
                      <el-tooltip 
                        effect="light"  
                        placement="bottom" 
                        :disabled="!scope.row.isMissingChoiceOne" 
                        :open-delay="200" 
                        :content="allChoiceOneStr"
                      >
                        <span> {{ scope.row.fields }} 
                          <em v-if="scope.row.isMissingChoiceOne" class="el-icon-question" /> 
                        </span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
                
                <template v-if="isAllChoiceOne">
                  <div class="isAllChoiceOne"> * {{ $t('excelUpload.MustChoiceOne') }} ： {{ allChoiceOneStr }} </div>
                </template>
              </el-collapse-item>
            </template>
            <template v-if="isDuplicate">
              <el-collapse-item :title="$t(`excelUpload.duplicate_rows_num`)" name="duplicateRows">
                <el-table :data="duplicateData" max-height="600" stripe>
                  <el-table-column property="row" :label="$t(`excelUpload.index`)" width="100" />
                  <el-table-column property="userId" :label="$t(`excelUpload.userId`)" />
                </el-table>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
      </template> 
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SuccessDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    duplicatedData: {
      type: Object,
      default: () => []
    },
    missingRequiredData: {
      type: Object,
      default: () => []
    },
    countTotal: {
      type: Number,
      default: 0
    },
    countSuccess: {
      type: Number,
      default: 0
    },
    countMissing: {
      type: Number,
      default: 0
    },
    countDuplicate: {
      type: Number,
      default: 0
    },
    changeFile: {
      type: Boolean,
      default: false
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeNames: ['missingFields', 'duplicateRows'], // collapse use
      missingData: [],
      duplicateData: [],
      allChoiceOneStr: '',
      isAllChoiceOne: false
    }
  },
  computed: {
    isDuplicate() {
      return this.countDuplicate > 0 
    },
    isMissing() {
      return this.countMissing > 0 
    },
    returnStatus() {
      let result = ''
      if (this.isMissing) {
        result = 'error'
      } else if (this.isDuplicate) {
        result = 'warning'
      } else {
        result = 'success'
      }
      return result
    },
    isNonSuccess() {
      const successNum = this.countSuccess
      let result = false
      if (this.returnStatus === 'warning' || this.returnStatus === 'success') {
        if (successNum === 0) {
          result = true
        }
      } 
      return result
    },
    isDataCorrect() {
      return !this.isMissing && !this.isDuplicate && this.isTest
    }
  },
  mounted() {
    this.initData()
    this.setDuplicateTable()
    this.setMissingTable()
  }, 
  methods: {
    initData () {
      if (this.changeFile) {
        this.missingData = []
        this.duplicateData = []
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    setDuplicateTable() {
      const response = this.duplicatedData
      for (const key in response) {
        this.duplicateData.push({
          row: key,
          userId: response[key].user_id
        })
      }
    },
    setMissingTable() {
      const response = this.missingRequiredData
      for (const key in response) {
        let missingStr = response[key].all.join(', ')
        
        let isMissingChoiceOne = false
        if (response[key].all_choose_one.length !== 0) {
          // for table
          if (response[key].all.length !== 0) {
            missingStr = missingStr + ', *' + this.$t('excelUpload.missing_MustChoiceOne')
          } else {
            missingStr = missingStr + '*' + this.$t('excelUpload.missing_MustChoiceOne')
          }

          // 處理下方備註
          this.allChoiceOneStr = response[key].all_choose_one.map(item => {
            return this.$t(`field.${item}`)
          }).join(' ,')
          this.isAllChoiceOne = true

          // for tooltip
          isMissingChoiceOne = true
        }

        this.missingData.push({
          row: key,
          userId: response[key].user_id,
          fields: missingStr,
          isMissingChoiceOne: isMissingChoiceOne
        })
      }
    },
    handleCollapseChange(val) {
      // console.log(val)
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/index.scss";
.dialog-success {
  /* reset element-ui css */
  .el-dialog__body {
    padding: 0 20px 40px;
  }

  .el-result {
    display: block;
    padding: 0;
  }
  /* reset element-ui css */

  .el-dialog__header {
    position: relative;
  }

  .el-dialog__header .btn-copy{
    border: none;
    position: absolute;
    padding: 0;
    font-size: 16px;
    right: 50px;
  }

  .summary-card {
    text-align: center;
    box-shadow: 6px 6px 6px 0 rgb(0 0 0 / 10%);
    padding: 0;

    p {
    margin: 0px;
    }

    .card-number{
    font-size: 30px;
    }
    
  }
  .number-total{
      color: #606260;
    }
  .number-success{
    color: #67c23a;
  }
  .number-missing{
    color: #f56c6c;
  }
  .number-duplicate{
    color: #e6a23c;
  }

  .error-container{
    padding: 20px 75px 50px;
    font-size: 16px;
    line-height: 2;

    .error-title{
      font-size: 20px;
      font-weight: 700;
    }
  }

  .data-correct-container{
    font-size: 20px;
    padding-top: 20px;
    color: #67c23a;
    text-align: center;

    a{
      color: blue;
      border-bottom: px solid blue;
    }

    a:hover{
      transition: 0.25s;
      color: #67c23a;
      border-bottom: 1px solid #67c23a;
    }
  }
}

  .el-collapse {
    border-top: none;
    border-bottom: none;
  }
  
  .collapse-container{
    margin: 30px 10px;
    .isAllChoiceOne {
      color: #f56c6c;
    }

    .el-icon-question{
      color: #f56c6c;
    }
  }

  .el-collapse-item__header {
    font-size: 20px;
  }
</style>
